import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<div className="bg-gray-100 dark:bg-gray-900 dark:text-white py-10">
				<div className="max-w-6xl mx-auto p-5 bg-white dark:bg-gray-800 rounded-lg ">
					{/* Title */}
					<h1 className="text-4xl font-bold text-center mb-10 text-gray-800 dark:text-gray-100">
						{t("aboutUs.title")}
					</h1>

					{/* Office Section */}
					<div className="mb-10">
						<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-5">
							{t("aboutUs.officeSection.title")}
						</h2>
						<p className="text-gray-600 dark:text-gray-300 text-lg mb-5">
							{t("aboutUs.officeSection.description")}
						</p>
					</div>
					<div className="flex justify-center mb-10 gap-4 flex-wrap ">
						<img
							src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/uqiyueqd4odekrzl4de6"
							alt={t("aboutUs.officeSection.imageAlt")}
							className="max-h-[850px] object-cover rounded-lg shadow-md"
						/>
					</div>

					{/* Location Section */}
					<div className="mb-10">
						<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-5">
							{t("aboutUs.locationSection.title")}
						</h2>
						<p className="text-gray-600 dark:text-gray-300 text-lg mb-5">
							{t("aboutUs.locationSection.description")}
						</p>

						<iframe
							title={t("aboutUs.locationSection.mapTitle")}
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.673525164171!2d28.215285776146898!3d36.437813680026885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149520aa35c1e39d%3A0x3a0d9aaf14f4ea2d!2sKodrigktonos%2049%2C%20Rodos%2085100%2C%20Greece!5e0!3m2!1sen!2sus!4v1696767722720!5m2!1sen!2sus"
							width="100%"
							height="450"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
							className="rounded-lg shadow-md"
						></iframe>
					</div>

					{/* Founder Section */}
					<div className="mb-10">
						<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-5">
							{t("aboutUs.founderSection.title")}
						</h2>
						<p className="text-gray-600 dark:text-gray-300 text-lg mb-5">
							{t("aboutUs.founderSection.description")}
						</p>
						<div className="flex  mb-10">
							<img
								src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/st8dstbjgwzt7iomopjl"
								alt={t("aboutUs.founderSection.imageAlt")}
								className="max-h-[600px] min-w-full object-cover rounded-lg shadow-md"
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default AboutUs;
